import { CreateControllerFn, IUser } from '@wix/yoshi-flow-editor';
import { NormalizedCacheObject } from '@apollo/client';
import { ssr } from '../../../../api/ssr.api';
import { dashify } from './utils/guid';
import settingsParams from '../../settingsParams';
import { PRODUCTION_HOSTS } from '@wix/wix-vod-constants/env';
import { FetchModule, FetchModuleProps } from './worker/fetch/fetchModule';
import { createModules, ModulesProps } from './worker/modules';
import appConfig from '../../../../../.application.json';
import { widgetLoaded } from '@wix/bi-logger-video/v2';
import { SiteInfoContext } from './common/providers/SiteInfoProvider';

export type ControllerProps = {
  gqlHost: string;
  instance: string;
  initialCacheData?: NormalizedCacheObject;
  currentUser: IUser;
  siteInfo: SiteInfoContext;
} & FetchModuleProps &
  ModulesProps;

const createController: CreateControllerFn = async (params) => {
  // For more info about controller structure,
  // check the docs: https://bo.wix.com/pages/yoshi/docs/editor-flow/structure-api/component/ooi-component#controller
  const {
    flowAPI: {
      bi,
      settings,
      httpClient,
      environment: { isSSR },
    },
    controllerConfig: {
      setProps,
      compId,
      wixCodeApi: {
        window: { viewMode },
        location: { baseUrl, query },
        site: { onInstanceChanged },
      },
      platformAPIs,
    },
  } = params;

  const channelId = dashify(settings.get(settingsParams.channelId)) as string;

  if (bi) {
    bi.report(
      widgetLoaded({
        channelID: channelId,
      }),
    );
  }

  const vodNoCache = query.vodNoCache === 'true' && !isSSR;

  onInstanceChanged((event) => {
    // update instance after user sign in/out.
    // clear the cache to force the client to refetch data with new instance
    const props: Partial<ControllerProps> = {
      instance: event.instance,
      initialCacheData: undefined,
    };
    setProps(props);
  }, appConfig.appDefinitionId);

  return {
    async pageReady() {
      const { controllerConfig } = params;
      const { wixCodeApi, appParams } = controllerConfig;
      const { instance, instanceId } = appParams;

      let initialCacheData;
      const gqlHost = viewMode === 'Site' ? baseUrl : PRODUCTION_HOSTS.manage;

      if (!vodNoCache) {
        try {
          const { data } = await httpClient.request(
            ssr(gqlHost, instance, channelId),
          );

          initialCacheData = data;
        } catch (e) {
          console.log('### error', e);
        }
      }

      const fetchModule = new FetchModule(params);

      const props: ControllerProps = {
        gqlHost,
        instance,
        initialCacheData,
        currentUser: wixCodeApi.user.currentUser,
        siteInfo: {
          currentPageId: wixCodeApi.site.currentPage?.id ?? '', // why currentPageId can be undefined?
          compId,
          instance,
          instanceId,
          siteUrl: wixCodeApi.location.url,
          query: wixCodeApi.location.query,
          // it's unknown why BI token can ever be undefined, don't want to add checks for now, leaving this if any errors come
          biToken:
            platformAPIs.bi?.biToken ??
            platformAPIs.bi?.metaSiteId ??
            'BI-TOKEN',
        },
        ...fetchModule.getInitialProps(),
        ...(await createModules(params)),
      };

      setProps(props);
    },
  };
};

export default createController;
