import { ControllerParams } from '@wix/yoshi-flow-editor';
import { PRODUCTION_HOSTS } from '@wix/wix-vod-constants/env';
import { buildReport, STATS_EVENTS } from '../../constants/stats/events';

const reportUrl = `${PRODUCTION_HOSTS.statsCollector}/collector/rest/collect-js`;

export type CollectorHandlers = {
  reportStats: (event: STATS_EVENTS, eventData: any) => void;
};

export function createCollectorHandlers(
  context: ControllerParams,
): CollectorHandlers {
  const {
    flowAPI: { httpClient },
    controllerConfig: {
      platformAPIs: { bi },
    },
  } = context;

  return {
    reportStats: (event: STATS_EVENTS, eventData: any) => {
      const ownerId = bi?.ownerId;
      const data = buildReport(event, eventData, ownerId);

      httpClient.post(reportUrl, data, {
        headers: {
          'Content-Type': 'application/json',
        },
        onError: () => {
          context.flowAPI.reportError('Error reporting stats to collector');
        },
      });
    },
  };
}
